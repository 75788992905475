
































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { validatePhone } from '@/assets/js/verification'
import { generateUUID, wxUrl, is_weixin, showWxLogin } from '@/assets/js/common'
@Component
export default class Login extends Vue {
  bindModal = false
  showWxLogin = showWxLogin
  has_code = 0
  btnLoading = false
  isCode = false
  ruleForm = {
    phone: '',
    password: ''
  }
  get isMob(): string {
    return this.$store.state.isMob
  }
  get validatePhone(): any {
    return validatePhone
  }
  rules = {
    phone: [{ validator: validatePhone, trigger: 'blur' }],
    password: [{ required: true,message: '请输入密码', trigger: 'blur' }]
  }
  @Watch('ruleForm.phone')
  onChildChanged(val: string, oldVal: string): void {
    if (val !== oldVal) {
      this.isCode = false
    }
  }

  get isDisableLogin(): boolean {
    return !this.ruleForm.phone || !this.ruleForm.password
  }
  // 图片验证
  imgVerification(): void {
    this.$http
      .post(this.$api.captcha_img)
      .then((res: any) => {
        if (res.data.meta.status_code === 200) {
          this.$RVerify.configure({
            album: res.data.data
          })
          this.$RVerify.action((res: any) => {
            if (res) {
              this.has_code = 1
              this.isCode = false
            } else {
              this.isCode = true
              this.has_code = 0
            }
          })
        } else {
          this.$message.error(res.data.meta.message)
        }
      })
      .catch((error: any) => {
        console.log('err', error)
      })
  }
  // 登录
  submitForm(): void {
    ;(this.$refs['ruleForm'] as vForm)?.validate((valid: any) => {
      if (valid) {
        if (this.isCode) {
          this.imgVerification()
        } else {
          this.login()
        }
      } else {
        return false
      }
    })
  }
  // 登录
  login(): void {
    this.btnLoading = true
    let row = {
      account: this.ruleForm.phone,
      password: this.ruleForm.password,
      has_code: this.has_code
    }
    this.$http
      .post(this.$api.login, row)
      .then((res: any) => {
        const msg = res.data.meta.message
        if (res.data.meta.status_code === 200) {
          const token = res.data.data.ticket
          // 设置cookie并跳转页面
          this.$store.commit('setToken', token)
        } else {
          if (res.data.data.img_code === 1) {
            this.imgVerification()
            this.isCode = true
          }
          if (res.data.meta.status_code === 460) {
            this.bindModal = true
          } else {
            this.$message.error(msg)
          }
        }
      })
      .catch((error: any) => {
        console.log('err', error)
      })
      .finally(() => {
        this.btnLoading = false
      })
  }
  // 第三方登录
  otherLogin(val: string): void {
    let url = location.origin
    let api = this.$api.login_wb
    let state = generateUUID()
    if (val === 'wb') {
      api = this.$api.login_wb
      window.location.href = `https://api.weibo.com/oauth2/authorize?client_id=3156984431&evt=1&redirect_uri=${url}${api}&response_type=code&state=${state}`
    } else if (val === 'qq') {
      api = this.$api.login_qq
      window.location.href = `https://graph.qq.com/oauth2.0/show?which=Login&display=pc&display=pc&client_id=101092227&response_type=code&scope=all&evt=1&redirect_uri=${url}${api}&state=${state}`
    }
  }

  // 短信登录
  smsLogin(): void {
    this.$router.push({ name: 'loginSms' })
  }

  // 忘记密码
  findPassword(): void {
    this.$router.push({ name: 'findPassword' })
  }

  // 微信
  clickWechat(): void {
    if (this.isMob && is_weixin) {
      window.location.href = wxUrl()
    } else {
      this.$router.push({ name: 'WeChatCode' })
    }
  }
}
